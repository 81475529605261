<template>    
    <v-card>
        <!-- Titulo del dialogo -->
        <v-row class="ma-0 pl-4 pr-12">
        <v-card-title class="py-4">
            <span>Editar Producto</span>
        </v-card-title>
        <v-spacer/>
        <v-checkbox v-model="editedItem.is_published" v-if="editedItem.product_type!='Variable'" label="Publicado"></v-checkbox>
        </v-row>

        <v-divider></v-divider>
            <!-- Formulario -->
        <v-form ref="form">
            <v-row class="py-0 px-6 ma-0">
                <v-col cols="12" sm="6" md="6" class="my-0 pb-0 pt-0">
                    <v-text-field :rules="[v => !!v || 'Campo requerido']" v-model="editedItem.name" label="Nombre*"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="my-0 pb-0 pt-0">
                    <v-autocomplete multiple v-model="editedItem.categories" :items="categories" label="Categoría" item-text="name" item-value="id">
                        <template slot="no-data">
                            <div class="px-6 pt-3">
                                No existen categorías relacionadas.
                                <v-text-field v-model="createCategory.name" label="Nueva Categoría" append-icon="mdi-plus" @click:append="saveCategory()"></v-text-field>
                            </div>
                        </template>
                    </v-autocomplete>
                </v-col>
            </v-row>
            <v-row class="py-0 px-6 ma-0">
                <v-col cols="12" sm="6" md="4" v-if="liga!='https://backendbdb.unocrm.mx/'" class="my-0 pb-0 pt-0">
                <v-text-field v-model="editedItem.code_one" label="Código 1"></v-text-field>
                    
                </v-col>
                <v-col cols="12" sm="6" :md="liga!='https://backendbdb.unocrm.mx/'?'4':'6'" class="my-0 pb-0 pt-0">
                    <v-autocomplete :rules="[v => !!v || 'Campo refquerido']" v-model="editedItem.unit_id" v-if="editedItem.product_type!='Variable'" :items="units" label="Unidad de Medida*" item-text="name" item-value="id">
                        <template slot="no-data">
                            <div class="px-6 pt-3">
                                No existen unidades de medida relacionadas.
                                <v-text-field v-model="createUnit.name" label="Nueva Unidad de Medida" append-icon="mdi-plus" @click:append="saveUnit()"></v-text-field>
                            </div>
                        </template>
                    </v-autocomplete>
                    
                </v-col>
                <v-col cols="12" sm="6" :md="liga!='https://backendbdb.unocrm.mx/'?'4':'6'" class="my-0 pb-0 pt-0">
                    <v-text-field v-model="editedItem.weight" label="Peso" suffix="Kg"></v-text-field>
                </v-col>
            </v-row>
            <v-row class="py-0 px-6 ma-0">
                <v-col cols="12" sm="6" md="6" class="my-0 pb-0 pt-0">
                    <v-text-field type="number" v-model="editedItem.ideal_inventory" v-if="editedItem.product_type!='Variable'" label="Inventario Mínimo"></v-text-field>
                    
                </v-col>
                <v-col cols="12" sm="6" md="6" class="my-0 pb-0 pt-0">
                    <v-text-field type="number" v-model="editedItem.cost" label="Costo"></v-text-field>
                </v-col>
            </v-row>
            <v-row class="py-0 px-6 ma-0">
                <v-col cols="12" sm="6" md="4" class="my-0 pb-0 pt-0">
                    <v-text-field :rules="[v => !!v || 'Campo requerido']" v-model="editedItem.sat_key_code" label="Código SAT"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4" class="my-0 pb-0 pt-0">
                    <v-select label="Impuestos Incluidos" :items="tax_included" v-model="editedItem.tax_included" item-value="value" item-text="text"></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="4" class="my-0 pb-0 pt-0">
                    <v-select label="Objeto de Impuesto" :items="taxabilities" v-model="editedItem.taxability" item-value="value" item-text="text"></v-select>
                </v-col>
                
            </v-row>

            <div>
                <v-row class="py-0 px-6 ma-0 pt-3">
                    <v-spacer/>
                    Impuestos
                    <v-spacer/>
                </v-row>
                <v-row class="py-0 px-6 ma-0" v-for="(tax, index) in editedItem.taxes" :key="index">
                    <v-col :cols="(12/5).toString()" class="pt-0">
                        <v-text-field v-model="tax.rate" type="number" suffix="%" label="Tasa" hint="Tasa del impuesto en fracción decimal."></v-text-field>
                    </v-col>
                    <v-col :cols="(12/5).toString()" class="pt-0">
                        <v-text-field v-model="tax.base" type="number" suffix="%" label="Base del impuesto"></v-text-field>
                    </v-col>
                    <v-col :cols="(12/5).toString()" class="pt-0">
                        <v-select v-model="tax.type" :items="['IVA', 'ISR', 'IEPS']" label="Tipo de impuesto"></v-select>
                    </v-col>
                    <v-col :cols="(12/5).toString()" class="pt-0">
                        <v-select v-model="tax.factor" :items="['Tasa', 'Cuota', 'Exento']" label="Tipo factor"></v-select>
                    </v-col>
                    <v-col :cols="(12/5).toString()" class="pt-0">
                        <v-select v-model="tax.withholding" :items="[{text:'Impuesto Retenido', value:true}, {text:'Impuesto Trasladado', value:false}]" label="Retención"></v-select>
                    </v-col>
                </v-row>
            </div>

            <v-row class="py-0 px-6 ma-0" v-if="liga=='https://backendbdb.unocrm.mx/'">
                <v-col cols="12" sm="6" :md="(12/branches.length).toString()" v-for="(branch, index) in branches" :key="index">
                    <v-text-field type="number" v-model="branch.inventory" :label="'Inventario ' +  branch.name"></v-text-field>
                </v-col>
            </v-row>
        </v-form>
        <v-row class="ma-0 pa-0">
            <v-col cols="12" sm="12" md="4" class="mb-0 pb-0 pt-6">
                <v-dialog v-model="dialog" width="800">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn style="font-size:12px;" text v-bind="attrs" v-on="on">
                            <v-icon class="mr-2">mdi-attachment</v-icon> Fotografía
                        </v-btn>
                    </template>
                    <v-card>
                        <v-card-title>
                            <v-row>
                                <v-col v-for="(image, n) in editedItem.images" :key="n" class="d-flex child-flex" cols="1">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-img :src="liga + 'files/items/'+image"
                                                aspect-ratio="1" class="grey lighten-2" v-bind="attrs" v-on="on">
                                                <template v-slot:placeholder>
                                                    <v-row class="fill-height ma-0" align="center" justify="center">
                                                        <v-progress-circular indeterminate color="grey lighten-5" ></v-progress-circular>
                                                    </v-row>
                                                </template>
                                                <v-btn small @click="deleteImage(n)" color="red" icon><v-icon>mdi-delete</v-icon></v-btn>
                                            </v-img>
                                        </template>
                                        <v-img width="350" height="auto" :src="liga + 'files/items/'+image"></v-img>
                                    </v-tooltip>
                                </v-col>
                            </v-row>
                        </v-card-title>
                        <vue-dropzone 
                            ref="myVueDropzone" 
                            id="dropzone" 
                            :options="dropzoneOptions" 
                            v-on:vdropzone-success="uploadSuccess" 
                            v-on:vdropzone-error="uploadError" 
                            v-on:vdropzone-removed-file="fileRemoved"/>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="dialog=false">Terminar</v-btn>
                        </v-card-actions>
                    </v-card>
                    <v-snackbar :color="snackbar2.color" v-model="snackbar2.show">
                        {{ snackbar2.message }}
                    </v-snackbar>
                </v-dialog>
            </v-col>
            <v-col cols="12" sm="12" md="8" class="mb-0 pb-0 pl-0">
                <v-card-actions class="pb-6">
                    <v-spacer></v-spacer>
                    <v-btn color="red darken-1" text @click="close">Cancelar</v-btn>
                    <v-btn color="primary" class="elevation-0 px-4" rounded  :loading="gris" :disabled="gris || !valid" @click="save">Guardar</v-btn>
                </v-card-actions>
            </v-col>
        </v-row>
        <v-snackbar :color="snackbar.color" v-model="snackbar.show">
            {{ snackbar.message }}
        </v-snackbar>
    </v-card>
</template>
<script>
import axios from "axios";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
export default {
    components: {
        vueDropzone: vue2Dropzone,
    },  
    props:{
        editedItem:Object
    },
    data:()=>({
        tax_included:[
            {value:true, text:"Los impuestos aplicables están incluídos en el precio y se desglosarán automáticamente al emitir la factura."},
            {value:false, text:"Indica que el precio no incluye impuestos, por lo que aquellos impuestos a aplicar se sumarán en el precio final."}
        ],
        taxabilities:[
            {value:"01", text:"No objeto de impuesto."},
            {value:"02", text:"Sí objeto de impuesto."},
            {value:"03", text:"Sí objeto de impuesto, pero no obligado a desglose."},
            {value:"04", text:"Sí objeto de impuesto, y no causa impuesto."},
            {value:"05", text:"Sí objeto de impuesto, IVA crédito PODEBI."},
        ], 
        e1: 1,
        dialog: false,
        dropzoneOptions: {
            url: process.env.VUE_APP_BACKEND_ROUTE + "api/v1/item/files",
            addRemoveLinks: true,
            thumbnailWidth: 150,
            dictDefaultMessage: 'Haz clic aquí o arrastra la(s) imagen(es) a subir.',
            dictFallbackMessage: "Tu navegador no puede subir archivos arrastarndolos a la pantalla.",
            dictFileTooBig: "File is too big ({{filesize}}MiB). Max filesize: {{maxFilesize}}MiB.",
            dictInvalidFileType: "No puede cargar archivos de este tipo.",
            dictCancelUpload: "Cancelar carga",
            dictCancelUploadConfirmation: "Estás seguro de que deseas cancelar esta carga?",
            dictRemoveFile: "Eliminar",
            dictMaxFilesExceeded: "No puedes subir más archivos.",
        },
        gris:false, 
        snackbar: {
            show: false,
            message: null,
            color: null
        },
        snackbar2: {
            show: false,
            message: null,
            color: null
        },
        index:0,
        disable:'',
        productTypes:[
            'Simple', 'Variable', 'Variación'
        ],
        types:[
            'Insumo', 'Fabricado', 'Producto Final'
        ],
        createCategory:{
            name:'',
        },
        createProvider:{
            name:'',
        },
        createUnit:{
            name:'',
        },
        rules: {
            required: value => !!value || 'Campo requerido',
        },
    }),
    watch:{
        editedItem:{
            handler(){
                console.log(this.editedItem)
                this.editedItem.unit_id = this.editedItem.unit.id
            }, deep:true
        }
    },
    created(){
        console.log(this.editedItem)
        this.editedItem.unit_id = this.editedItem.unit.id
    },
    computed:{
        valid(){
            if(
                (this.editedItem.name!='' && this.editedItem.name!=null && this.editedItem.name!=undefined) &&
                (this.editedItem.unit_id!='' && this.editedItem.unit_id!=null && this.editedItem.unit_id!=undefined)
            ){
                return true
            }else{
                return false
            }
        },
        liga(){
            return process.env.VUE_APP_BACKEND_ROUTE
        },
        rawMaterials:{
            get(){
                return this.$store.state.raw_material.raw_materials
            }
        },
        currentUser:{
            get(){
                return this.$store.state.currentUser.user
            }
        },
        categories:{
            get(){
                return this.$store.state.category.categories;
            }
        },
        providers:{
            get(){
                return this.$store.state.provider.providers;
            }
        },
        units:{
            get(){
                return this.$store.state.unit.units;
            }
        }
    },
    methods:{
        add() {
            this.editedItem.raw_materials.push({percentage:'', raw_material_id:''});
        },
        remove(index) {
            this.editedItem.raw_materials.splice(index, 1);
        },
        close () {
            this.gris = false
            this.index = 0
            //this.$refs.myVueDropzone.removeAllFiles()
            this.dialog = false
            this.$nextTick(() => {
                this.$emit("closeDialogItem", false);
            })
        },
        save () {
            this.$nextTick(() => {
                var perro = this.editedItem
                axios.put(process.env.VUE_APP_BACKEND_ROUTE + "api/v1/item/update",Object.assign(this.editedItem)).then(response=>{
                    location.reload()
                }).catch(error => {
                    this.snackbar = {
                        message: error.response.data.message,
                        color: 'error',
                        show: true
                    }
                    this.gris = false
                })
            })
        },
        saveCategory(){
            axios.post(process.env.VUE_APP_BACKEND_ROUTE + "api/v1/category/create",Object.assign(this.createCategory)).then(response=>{
                this.$store.dispatch('category/getCategories')
                this.disable = true
                this.createCategory.name=''
            }) 
        },
        saveProvider(){
            axios.post(process.env.VUE_APP_BACKEND_ROUTE + "api/v1/provider/create",Object.assign(this.createProvider)).then(response=>{
                this.$store.dispatch('provider/getProviders')
                this.disable = true
                this.createProvider.name=''
            }) 
        },
        saveUnit(){
            axios.post(process.env.VUE_APP_BACKEND_ROUTE + "api/v1/unit/create",Object.assign(this.createUnit)).then(response=>{
                this.$store.dispatch('unit/getUnits')
                this.disable = true
                this.createUnit.name=''
            }) 
        },
        uploadSuccess(file, response) {
            console.log('File Successfully Uploaded with file name: ' + response.file);
            this.fileName = response.file;
            this.editedItem.images[this.index] = this.fileName;
            this.index = this.index+1
        },
        uploadError(file, message) {
            console.log('error')
            this.snackbar2 = {
                message: message.message,
                color: 'error',
                show: true
            }
        },
        fileRemoved(file) {
            var name = this.editedItem.images.filter(image=>image.includes(file.name))[0]
            var index = this.editedItem.images.indexOf(name)
            this.editedItem.images.splice(index, 1);
            this.index = this.index-1
        },
        deleteImage(index) {
            if (confirm('Seguro que deseas eliminar esta imagen?')) {
                this.editedItem.images.splice(index, 1);
                this.index = this.index-1
            }
        }
    }
}
</script>